import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userManagement = createApi({
  reducerPath: 'userManagement',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      // Directly access the auth state
      const authState = getState().auth;
      const token = authState.userData?.token; // Assuming the token is stored in userData

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      headers.set('Accept', 'application/json');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    userManagement: builder.query({
      query: () => ({
        url: '/admin/users/userDetails',
        method: 'get',
      }),
    }),

    getUsers: builder.query({
      query: ({ page = 1, limit = 10, filters = [], orderBy = 'createdAt', order = 'asc' }) => {
        // Convert filters to query parameters
        const filterParams = filters
          .map((filter) => `${encodeURIComponent(filter.column)}=${encodeURIComponent(filter.value)}`)
          .join('&');

        // Construct the full URL with filters, sorting, and pagination
        const url = `/admin/users/getUsers?page=${page}&limit=${limit}&orderBy=${encodeURIComponent(
          orderBy
        )}&order=${encodeURIComponent(order)}${filterParams ? `&${filterParams}` : ''}`;

        return url;
      },
    }),

    getUserById: builder.query({
      query: ({ _id }) => `/admin/users/${_id}`,
    }),

    createUser: builder.mutation({
      query: (userData) => ({
        url: '/admin/users/createUser', // Replace with your actual endpoint path
        method: 'POST',
        body: userData,
      }),
    }),

    setUserById: builder.mutation({
      query: (payload) => ({
        url: `admin/users/updateUser/${payload._id}`,
        method: 'POST',
        body: payload?.preparedData,
      }),
    }),

    deleteUserById: builder.mutation({
      query: (userId) => ({
        url: `/admin/users/${userId}`,
        method: 'DELETE',
      }),
    }),
    setStatusByid: builder.mutation({
      query: (payload) => ({
        url: `/admin/users/updateMultipleUsersStatus`,
        method: 'POST',
        body: { userIds: [payload?.userId], status: payload?.status },
      }),
    }),
    deleteMultipleUserById: builder.mutation({
      query: (payload) => ({
        url: '/admin/users/deleteMultipleUsers',
        method: 'POST',
        body: { userIds: payload },
      }),
    }),

    banMultipleUserById: builder.mutation({
      query: (payload) => ({
        url: '/admin/users/updateMultipleUsersStatus',
        method: 'POST',
        body: { userIds: payload?.userIds, status: payload?.status },
      }),
    }),

    placesAutocomplete: builder.query({
      query: (input) => ({
        url: `/admin/users/placesAutocomplete`,
        method: 'GET',
        params: { input },
      }),
      transformResponse: (response) => response.predictions || [], // Adjust based on response format
    }),

    uploadImage: builder.mutation({
      query: (formData) => ({
        url: '/admin/users/uploadImages',
        method: 'POST',
        body: formData,
      }),
    }),

    sendGift: builder.mutation({
      query: (payload) => ({
        url: '/admin/gifts/sendGift',
        method: 'POST',
        body: { senderUserId: payload.fromUserId, recipientUserIds: payload.toUserIds, gift: payload.gift },
      }),
    }),

    sendMessage: builder.mutation({
      query: (payload) => ({
        url: '/admin/chat/getUsersRooms',
        method: 'POST',
        body: { senderUser: payload.fromUserId, recipientUserIds: payload.toUserIds },
      }),
    }),

    sendLike: builder.mutation({
      query: (payload) => ({
        url: '/admin/likes/addLike',
        method: 'POST',
        body: { likerUserId: payload.fromUserId, likedUserIds: payload.toUserIds },
      }),
    }),
  }),
});

export const {
  useUserManagementQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useSetUserByIdMutation,
  useDeleteUserByIdMutation,
  useSetStatusByidMutation,
  usePlacesAutocompleteQuery,
  useUploadImageMutation,
  useCreateUserMutation,

  useDeleteMultipleUserByIdMutation,
  useBanMultipleUserByIdMutation,
  useSendGiftMutation,
  useSendMessageMutation,
  useSendLikeMutation,
} = userManagement;
