import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

import Iconify from '../../../components/iconify';
import UserAutocomplete from '../../../components/crud/UserAutocomplete';
import MessageModal from '../../../components/crud/MessageModal';

import {
  useDeleteMultipleUserByIdMutation,
  useBanMultipleUserByIdMutation,
  useSendGiftMutation,
  useSendLikeMutation,
} from '../../../redux/userManagement/userManagementApi';

function Actions({ selectedUsers, onActionDone, socket }) {
  const [deleteMultipleUsers] = useDeleteMultipleUserByIdMutation();
  const [banMultipleUserByIds] = useBanMultipleUserByIdMutation();
  const [sendGift] = useSendGiftMutation();
  const [sendLike] = useSendLikeMutation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [giftModalOpen, setGiftModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [likeModalOpen, setLikeModalOpen] = useState(false);
  const [selectedGift, setSelectedGift] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleDeleteMultiple = async () => {
    try {
      const confirmation = window.confirm('Are you sure you want to delete these Users?');
      if (confirmation) {
        const userIds = selectedUsers.map((user) => user.userId);

        deleteMultipleUsers(userIds);
        toast.success('Users has been Deleted');
        onActionDone();
      }
    } catch (error) {
      console.log('error');
    }
  };

  const handleBanMultiple = async () => {
    try {
      const confirmation = window.confirm('Are you sure you want to ban these Users?');
      if (confirmation) {
        const userIds = selectedUsers.map((user) => user.userId);

        await banMultipleUserByIds({ userIds, status: 'banned' });

        toast.success('Selected Users BANNED!');
        onActionDone();
      }
    } catch (error) {
      console.log('BAN ERROR ===== ', error);
    }
  };

  const handleGiftSubmit = async () => {
    if (selectedUser && selectedGift) {
      try {
        const response = await sendGift({
          fromUserId: selectedUser._id,
          toUserIds: selectedUsers.map((user) => user.userId),
          gift: selectedGift,
        });

        const successfulGifts = response.data?.data?.successfulGifts || [];
        const failedGifts = response.data?.data?.failedGifts || [];

        // Helper to get fullName from userId
        const getFullName = (userId) =>
          selectedUsers.find((user) => user.userId === userId)?.fullName || 'Unknown User';

        // Show a toast for each successful gift
        successfulGifts.forEach((gift) => {
          const fullName = getFullName(gift.recipientUserId);
          toast.success(`${fullName} - ${gift.message}`);
        });

        // Show a toast for each failed gift
        failedGifts.forEach((gift) => {
          const fullName = getFullName(gift.recipientUserId);
          toast.error(`${fullName} - ${gift.message}`);
        });

        setGiftModalOpen(false);
        setSelectedUser(null);
        onActionDone();
      } catch (error) {
        console.error(error);
        toast.error('Failed to send gift');
      }
    } else {
      toast.error('Please select a user and a gift');
    }
  };

  const handleLikeSubmit = async () => {
    try {
      // Send like request and get structured response with successful and failed likes
      const response = await sendLike({
        fromUserId: selectedUser._id,
        toUserIds: selectedUsers.map((user) => user.userId),
      });

      // Get successful and failed likes
      const successfulLikes = response.data?.data?.successfulLikes || [];
      const failedLikes = response.data?.data?.failedLikes || [];

      // Map likedUserId to fullName using selectedUsers
      const getFullName = (likedUserId) =>
        selectedUsers.find((user) => user.userId === likedUserId)?.fullName || 'Unknown User';

      // Show a toast for each successful like
      successfulLikes.forEach((like) => {
        const fullName = getFullName(like.likedUserId);
        toast.success(`${fullName} - ${like.message}`);
      });

      // Show a toast for each failed like
      failedLikes.forEach((like) => {
        const fullName = getFullName(like.likedUserId);
        toast.error(`${fullName} - ${like.message}`);
      });

      // Reset state and close modal if needed
      setLikeModalOpen(false);
      setSelectedUser(null);
      onActionDone();
    } catch (error) {
      console.error(error);
      toast.error('Failed to send like');
    }
  };

  const handleOpenMessageModal = () => setMessageModalOpen(true);
  const handleCloseMessageModal = () => setMessageModalOpen(false);

  const open = Boolean(anchorEl);
  const id = open ? 'actions-popover' : undefined;

  return (
    <Box display="flex" gap={2} mr={2}>
      <Button disabled={selectedUsers.length === 0} onClick={handleOpenPopover} color="primary">
        <Iconify icon="mdi:menu" />
        <Typography variant="button" ml={1}>
          Actions
        </Typography>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box p={2} display="flex" flexDirection="column" gap={2}>
          <Button
            onClick={() => {
              handleClosePopover();
              setGiftModalOpen(true);
            }}
            variant="contained"
            startIcon={<Iconify icon="mdi:gift-outline" />}
            color="primary"
          >
            Send Gift
          </Button>

          <Button
            onClick={() => {
              handleClosePopover();
              handleOpenMessageModal();
            }}
            variant="contained"
            startIcon={<Iconify icon="mdi:message-outline" />}
            color="secondary"
          >
            Send Message
          </Button>

          <Button
            onClick={() => {
              handleClosePopover();
              setLikeModalOpen(true);
            }}
            variant="contained"
            startIcon={<Iconify icon="mdi:heart-outline" />}
            color="success"
          >
            Send Like
          </Button>

          <Button
            onClick={() => {
              handleClosePopover();
              handleDeleteMultiple();
            }}
            variant="contained"
            startIcon={<Iconify icon="mdi:delete-outline" />}
            color="error"
          >
            Delete Selected Users
          </Button>

          <Button
            onClick={() => {
              handleClosePopover();
              handleBanMultiple();
            }}
            variant="contained"
            startIcon={<Iconify icon="mdi:block-helper" />}
            color="warning"
          >
            Ban Selected Users
          </Button>
        </Box>
      </Popover>

      {/* Gift Modal */}
      <Dialog open={giftModalOpen} onClose={() => setGiftModalOpen(false)}>
        <DialogTitle>Send Gift</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <Box mt={2}>
            <UserAutocomplete onUserSelected={setSelectedUser} />
          </Box>
          <FormControl fullWidth margin="normal">
            <InputLabel id="select-gift-label">Select Gift</InputLabel>
            <Select
              labelId="select-gift-label"
              value={selectedGift}
              onChange={(e) => setSelectedGift(e.target.value)}
              label="Select Gift"
            >
              <MenuItem value="heart">heart</MenuItem>
              <MenuItem value="rose">rose</MenuItem>
              <MenuItem value="kiss">kiss</MenuItem>
              <MenuItem value="love">love</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setGiftModalOpen(false);
              setSelectedUser(null);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleGiftSubmit} color="primary" disabled={!selectedUser || !selectedGift}>
            Send Gift
          </Button>
        </DialogActions>
      </Dialog>

      <MessageModal
        open={messageModalOpen}
        onClose={handleCloseMessageModal}
        toUserIds={selectedUsers}
        socket={socket}
      />

      {/* Like Modal */}
      <Dialog open={likeModalOpen} onClose={() => setLikeModalOpen(false)}>
        <DialogTitle>Send Like</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <Box mt={2}>
            <UserAutocomplete onUserSelected={setSelectedUser} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setLikeModalOpen(false);
              setSelectedUser(null);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleLikeSubmit} color="primary" disabled={!selectedUser}>
            Send Like
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Actions;
