import React, { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import { io } from 'socket.io-client';
import { useLocation } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Checkbox,
  CircularProgress,
} from '@mui/material';

import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import MultiFilter from '../components/crud/MultiFilter';
import DrawerForm from '../components/crud/DrawerForm';
import { filtersConfig, fieldsConfig } from '../sections/@dashboard/user/configs';
import Actions from '../sections/@dashboard/user/Actions';

import {
  useGetUsersQuery,
  useSetUserByIdMutation,
  useCreateUserMutation,
} from '../redux/userManagement/userManagementApi';

const TABLE_HEAD = [
  { id: 'fullName', label: 'Name', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'subscriptionType', label: 'Subscription Type', alignRight: false },
  { id: 'mobileNumber', label: 'Mobile Number', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'createdAt', label: 'Created', alignRight: false },
];

const STORAGE_KEY = 'userPageState';

export default function UserPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fullName = queryParams.get('fullName');

  const [setUserById] = useSetUserByIdMutation();
  const [createUser] = useCreateUserMutation(); // Initialize createUser mutation

  const initialPage = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
  const [page, setPage] = useState(initialPage.page || 1);
  const [order, setOrder] = useState(initialPage.order || 'desc');
  const [orderBy, setOrderBy] = useState(initialPage.orderBy || 'createdAt');
  const [filters, setFilters] = useState(initialPage.filters || [{ column: '', value: '' }]);

  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedRows, setSelectedRows] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editData, setEditData] = useState({}); // Holds data for editing

  const socketRef = useRef(null); // useRef to store socket instance

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_API_URL);
    console.log('connecting');

    return () => {
      socketRef.current.disconnect(); // Disconnect on unmount
      console.log('disconnecting');
    };
  }, []);

  useEffect(() => {
    if (fullName) {
      setPage(1);
      setFilters([{ column: 'fullName', value: fullName }]);
    }
  }, [fullName]);

  useEffect(() => {
    // Save state to local storage on any change to these values
    const userPageState = { page, order, orderBy, filters };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(userPageState));
  }, [page, order, orderBy, filters]);

  const {
    data: users,
    isFetching,
    refetch,
  } = useGetUsersQuery({ page, limit: rowsPerPage, filters, orderBy, order }, { refetchOnMountOrArgChange: true });

  const applyFilters = (filters) => {
    setFilters(filters); // Store the active filters
    refetch({ ...filters, page, limit: rowsPerPage }); // Refetch data with filters
    setSelectedRows([]); // Clear selected rows
  };

  const handleOpenDrawer = (data = {}) => {
    setEditData(data); // If data is provided, it's for editing; otherwise, it's for creating
    setDrawerOpen(true);
  };

  const handleSave = async (data) => {
    try {
      if (editData._id) {
        // Updating user
        await setUserById({ _id: editData._id, preparedData: data }).unwrap();
        toast.success('User updated successfully!');
      } else {
        // Create a new user
        const response = await createUser(data).unwrap();
        toast.success('User created successfully!');
      }
      setDrawerOpen(false);
      refetch();
    } catch (error) {
      // Check if error response contains validation details
      if (error?.data?.details) {
        console.log('inside ');
        error.data.details.forEach((err) => {
          toast.error(err); // Show each validation error message
        });
      } else {
        toast.error('Failed to save user data');
      }
      console.error('Save error:', error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    refetch({ page, limit: rowsPerPage, filters, order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Update the page (TablePagination uses 0-based index)
    refetch({ page: newPage + 1, limit: rowsPerPage }); // Refetch with the new page and limit
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to the first page when changing the rows per page
    refetch({ page: 1, limit: parseInt(event.target.value, 10) }); // Refetch with the new limit
  };

  const isNotFound = !users?.data.length;

  const handleToggleRow = (userId, fullName) => {
    // Check if the user is already selected
    if (selectedRows.some((row) => row.userId === userId)) {
      // Remove the user from the selection if already included
      setSelectedRows(selectedRows.filter((row) => row.userId !== userId));
    } else {
      // Add the user with ID and name
      setSelectedRows([...selectedRows, { userId, fullName }]);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Users
        </Typography>

        <Button variant="contained" onClick={() => handleOpenDrawer()}>
          Create User
        </Button>
      </Stack>
      <Card>
        <Stack direction="row" alignItems="flex-start">
          <Scrollbar>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={1} mt={1}>
              <MultiFilter filtersConfig={filtersConfig} filters={filters} applyFilters={applyFilters} />

              <Actions
                selectedUsers={selectedRows}
                socket={socketRef.current}
                onActionDone={() => {
                  refetch();
                  setSelectedRows([]);
                }}
              />
            </Stack>

            <TableContainer sx={{ minWidth: 800 }}>
              {isFetching && (
                <Stack
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white overlay
                    zIndex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress />
                </Stack>
              )}

              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users?.data?.length || 0}
                  onRequestSort={handleRequestSort}
                  hasSelectAll
                />
                <TableBody>
                  {users?.data.map((row) => (
                    <TableRow
                      key={row._id}
                      hover
                      tabIndex={-1}
                      role="none"
                      sx={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDrawer(row);
                      }}
                    >
                      <TableCell align="left">
                        <Checkbox
                          checked={selectedRows.some((selectedRow) => selectedRow.userId === row._id)}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleToggleRow(row._id, row.fullName);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar
                            sx={{ cursor: 'pointer' }}
                            alt=""
                            src={`${row?.profileImages?.find((item) => item?.orderId === 1)?.chatUri}`}
                          />
                          <Typography variant="subtitle2" noWrap>
                            {row?.fullName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        <Label color={row?.gender === 'male' ? 'primary' : 'secondary'}>
                          {row?.gender === 'male' ? 'Male' : 'Female'}
                        </Label>
                      </TableCell>
                      <TableCell align="left">
                        <Label color={row?.subscriptionType === 'paid' ? 'success' : 'default'}>
                          {row?.subscriptionType === 'paid' ? 'Paid Subscription' : 'Free Subscription'}
                        </Label>
                      </TableCell>

                      <TableCell align="left">{row?.mobileNumber}</TableCell>
                      <TableCell align="left">
                        <Label color={(row?.status === 'banned' && 'error') || 'success'}>{row?.status}</Label>
                      </TableCell>
                      <TableCell align="left">
                        {row?.createdAt ? dayjs(row.createdAt).format('DD/MM/YYYY, h:mm A') : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">No results found &nbsp;</Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Stack>
      </Card>

      <TablePagination
        rowsPerPageOptions={[30, 50, 100]}
        component="div"
        count={users?.pagination?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <DrawerForm
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        fieldsConfig={fieldsConfig}
        initialData={editData}
        onSave={handleSave}
      />

      <ToastContainer />
    </>
  );
}
