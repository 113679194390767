import React, { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  CircularProgress,
  TablePagination,
  Button,
  Box,
} from '@mui/material';
import { io } from 'socket.io-client';
import { useSendLikeMutation } from '../redux/userManagement/userManagementApi';

import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import MultiFilter from '../components/crud/MultiFilter';
import MessageModal from '../components/crud/MessageModal';
import { filtersConfig } from '../sections/@dashboard/interaction/configs';
import { useGetInteractionsQuery } from '../redux/interactions/interactionsApi';

const TABLE_HEAD = [
  { id: 'user', label: 'User', alignRight: false },
  { id: 'relatedUser', label: 'Related User', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'content', label: 'Content', alignRight: false },
  { id: 'createdAt', label: 'Created', alignRight: false },
];

const STORAGE_KEY = 'interactionPageState';

export default function InteractionsPage() {
  const navigate = useNavigate();

  const [sendLike] = useSendLikeMutation();

  const initialPage = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
  const [page, setPage] = useState(initialPage.page || 1);
  const [order, setOrder] = useState(initialPage.order || 'desc');
  const [orderBy, setOrderBy] = useState(initialPage.orderBy || 'createdAt');
  const [filters, setFilters] = useState(initialPage.filters || [{ column: '', value: '' }]);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [selectedInteraction, setSelectedInteraction] = useState(null);

  // Fetch notifications query
  const {
    data: interactions,
    isFetching: isLoading,
    refetch,
  } = useGetInteractionsQuery(
    { page, limit: rowsPerPage, filters, orderBy, order },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    // Save state to local storage on any change to these values
    const userPageState = { page, order, orderBy, filters };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(userPageState));
  }, [page, order, orderBy, filters]);

  const socketRef = useRef(null); // useRef to store socket instance

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_API_URL);
    console.log('connecting');

    return () => {
      socketRef.current.disconnect(); // Disconnect on unmount
      console.log('disconnecting');
    };
  }, []);

  const applyFilters = (filters) => {
    setFilters(filters); // Store the active filters
    refetch({ ...filters, page, limit: rowsPerPage }); // Refetch data with filters
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    refetch({ page, limit: rowsPerPage, filters, order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Update the page (TablePagination uses 0-based index)
    refetch({ page: newPage + 1, limit: rowsPerPage }); // Refetch with the new page and limit
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to the first page when changing the rows per page
    refetch({ page: 1, limit: parseInt(event.target.value, 10) }); // Refetch with the new limit
  };

  const notificationColor = (type) => {
    switch (type) {
      case 'like':
        return 'primary';
      case 'match':
        return 'secondary';
      case 'chat':
        return 'info';
      case 'gift':
        return 'warning';
      default:
        return 'default';
    }
  };

  const generateContent = (interaction) => {
    const { metadata } = interaction;

    return (
      <>
        {interaction.type === 'like' && (
          <>
            <Typography>
              ❤️ <strong>{interaction.user.fullName}</strong> liked <strong>{interaction.relatedUser.fullName}</strong>
            </Typography>
            <Box mt={1}>
              <Button onClick={() => handleLikeBack(interaction)} size="small" variant="outlined">
                Like Back
              </Button>
            </Box>
          </>
        )}
        {interaction.type === 'match' && (
          <Typography>
            💘 <strong>{interaction.user.fullName}</strong> matched with{' '}
            <strong>{interaction.relatedUser.fullName}</strong>
          </Typography>
        )}
        {interaction.type === 'message' && (
          <>
            <Typography>
              💬 <strong>{interaction.user.fullName}</strong> sent a message to{' '}
              <strong>{interaction.relatedUser.fullName}</strong>
            </Typography>
            {metadata.image && (
              <img src={metadata.image} alt="Message attachment" style={{ maxWidth: '100px', marginTop: '8px' }} />
            )}
            {metadata.text && (
              <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                {metadata.text}
              </Typography>
            )}
            <Box mt={1}>
              <Button onClick={() => handleReply(interaction)} size="small" variant="outlined">
                Reply
              </Button>
            </Box>
          </>
        )}
        {interaction.type === 'gift' && (
          <Typography>
            🎁 <strong>{interaction.user.fullName}</strong> sent a <strong>{metadata.gift}</strong> to{' '}
            <strong>{interaction.relatedUser.fullName}</strong>
          </Typography>
        )}
      </>
    );
  };

  const handleNavigateToUser = (fullName) => {
    navigate(`/dashboard/user?fullName=${encodeURIComponent(fullName)}`);
  };

  const handleReply = (interaction) => {
    setSelectedInteraction(interaction);
    setMessageModalOpen(true);
  };

  const handleLikeBack = async (interaction) => {
    try {
      // Send like request and get structured response with successful and failed likes
      const response = await sendLike({
        fromUserId: interaction.relatedUserId,
        toUserIds: [interaction.userId],
      });

      // Get counts of successes and failures directly from the response
      const failureCount = response.data?.data?.failedLikes.length;

      // Show a message based on the results
      if (failureCount > 0) {
        toast.error(`Like failed.`);
      } else {
        toast.success('like sent successfully!');
      }

      // Reset state and close modal if needed
      setSelectedInteraction(null);

      if (page === 1) {
        refetch({ page: 1, limit: 10 });
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to send like');
    }
  };

  const handleCloseMessageModal = () => {
    setMessageModalOpen(false);

    if (page === 1) {
      setTimeout(() => {
        refetch({ page: 1, limit: 10 });
      }, 1000);
    }
  };

  const isNotFound = !interactions?.data.length && !isLoading;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Interactions
        </Typography>
      </Stack>
      <Card>
        <Stack direction="row" alignItems="flex-start">
          <Scrollbar>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={1} mt={1}>
              <MultiFilter filtersConfig={filtersConfig} filters={filters} applyFilters={applyFilters} />
            </Stack>

            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading && (
                <Stack
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    zIndex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress />
                </Stack>
              )}

              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={interactions?.data?.length || 0}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {interactions?.data.map((row) => (
                    <TableRow key={row._id} tabIndex={-1} role="none">
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar
                            sx={{ cursor: 'pointer' }}
                            alt=""
                            src={`${row?.user.profileImages?.find((item) => item?.orderId === 1)?.chatUri}`}
                          />
                          <Typography
                            variant="subtitle2"
                            noWrap
                            onClick={() => handleNavigateToUser(row?.user.fullName)}
                            sx={{
                              cursor: 'pointer',
                              color: 'primary.main',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                          >
                            {row?.user.fullName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar
                            sx={{ cursor: 'pointer' }}
                            alt=""
                            src={`${row?.relatedUser.profileImages?.find((item) => item?.orderId === 1)?.chatUri}`}
                          />
                          <Typography
                            variant="subtitle2"
                            noWrap
                            onClick={() => handleNavigateToUser(row?.relatedUser.fullName)}
                            sx={{
                              cursor: 'pointer',
                              color: 'primary.main',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                          >
                            {row?.relatedUser.fullName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        <Label color={notificationColor(row?.type)}>{row?.type}</Label>
                      </TableCell>
                      <TableCell align="left">{generateContent(row)}</TableCell>
                      <TableCell align="left">
                        {row?.createdAt ? dayjs(row.createdAt).format('DD/MM/YYYY, h:mm A') : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: 'center' }}>
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">No results found &nbsp;</Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Stack>
      </Card>

      <TablePagination
        rowsPerPageOptions={[30, 50, 100]}
        component="div"
        count={interactions?.pagination?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <MessageModal
        open={messageModalOpen}
        onClose={handleCloseMessageModal}
        fromUserId={selectedInteraction?.relatedUserId}
        toUserIds={[{userId: selectedInteraction?.userId, fullName: selectedInteraction?.user?.fullName}]}
        socket={socketRef.current}
      />

      <ToastContainer />
    </>
  );
}
