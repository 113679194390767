import React, { useState } from 'react';
import { Button, Stack, Typography, Box, Card } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import { useUploadImageMutation } from '../../redux/userManagement/userManagementApi';
import Iconify from '../iconify';

const ImageInput = styled('input')({
  display: 'none',
});

const ImageUpload = ({ profileImages = [], onImagesUpdate }) => {
  const [uploadImage, { isLoading }] = useUploadImageMutation();

  // Function to find the lowest available orderId
  const findLowestAvailableOrderId = () => {
    const existingOrderIds = profileImages.map((image) => image.orderId).sort((a, b) => a - b);

    let lowestAvailable = 1;

    // eslint-disable-next-line no-restricted-syntax
    for (const id of existingOrderIds) {
      if (id === lowestAvailable) {
        lowestAvailable += 1;
      } else {
        break; // Stop as soon as there's a gap
      }
    }

    // If the loop completes with no gaps, return the next integer after the last id
    return lowestAvailable.toString() === existingOrderIds[existingOrderIds.length - 1]
      ? lowestAvailable + 1
      : lowestAvailable;
  };

  // File selection and upload handler
  const handleFileSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      try {
        const orderId = findLowestAvailableOrderId();
        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('orderId', orderId);

        const response = await uploadImage(formData).unwrap();
        onImagesUpdate([...profileImages, ...response.profileImages]);
      } catch (error) {
        console.error('Image upload failed:', error);
      }
    }
  };

  // Delete image handler
  const handleDelete = (orderId) => {
    const updatedImages = profileImages.filter((image) => image.orderId !== orderId);
    onImagesUpdate(updatedImages);
  };

  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h6">Profile Images</Typography>

      <ImageList cols={3} gap={8} sx={{ maxWidth: 500 }}>
        {[...profileImages]
          .sort((a, b) => a.orderId - b.orderId)
          .map((image) => (
            <ImageListItem
              key={image.orderId}
              sx={{
                position: 'relative',
                borderRadius: 2,
                borderColor: '#4B164C',
                borderStyle: 'solid',
                borderWidth: 1,
                overflow: 'hidden',
              }}
            >
              <img
                src={image.uri}
                alt={`Profile ${image.orderId}`}
                loading="lazy"
                style={{ borderRadius: '8px', objectFit: 'cover', height: 100 }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bgcolor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  px: 0.8,
                  py: 0.4,
                  fontSize: 12,
                  fontWeight: 'bold',
                  borderRadius: '0 0 8px 0',
                }}
              >
                {`ID: ${image.orderId}`}
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 2,
                  right: 2,
                  display: 'flex',
                  borderRadius: '50%',
                }}
              >
                <IconButton aria-label="delete" color="primary" onClick={() => handleDelete(image.orderId)}>
                  <Iconify icon="mdi:delete" width={20} height={20} />
                </IconButton>
              </Box>
            </ImageListItem>
          ))}
      </ImageList>

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="upload-button">
        <ImageInput id="upload-button" type="file" accept="image/*" onChange={handleFileSelect} />
        <Button
          variant="outlined"
          component="span"
          startIcon={<Iconify icon="mdi:upload" />}
          sx={{ mt: 2 }}
          disabled={isLoading} // Disable button while loading
        >
          {isLoading ? 'Uploading...' : 'Select Image'}
        </Button>
      </label>
    </Stack>
  );
};

export default ImageUpload;
