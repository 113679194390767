import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const interactions = createApi({
  reducerPath: 'interactions',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      // Directly access the auth state
      const authState = getState().auth;
      const token = authState.userData?.token; // Assuming the token is stored in userData

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      headers.set('Accept', 'application/json');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getInteractions: builder.query({
      query: ({ page = 1, limit = 10, filters = [], orderBy = 'createdAt', order = 'asc' }) => {
        // Convert filters to query parameters
        const filterParams = filters
          .map((filter) => `${encodeURIComponent(filter.column)}=${encodeURIComponent(filter.value)}`)
          .join('&');

        // Construct the full URL with filters, sorting, and pagination
        const url = `/admin/interactions/getInteractions?page=${page}&limit=${limit}&orderBy=${encodeURIComponent(
          orderBy
        )}&order=${encodeURIComponent(order)}${filterParams ? `&${filterParams}` : ''}`;

        return url;
      },
    }),
  }),
});

export const {
  useGetInteractionsQuery,
} = interactions;
