// UserAutocomplete.js
import React, { useState } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import { useGetUsersQuery } from '../../redux/userManagement/userManagementApi';

function UserAutocomplete({ onUserSelected }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [filters, setFilters] = useState([{ column: 'fullName', value: '' }]);

  const { data: usersData, isLoading, refetch } = useGetUsersQuery(
    { page: 1, limit: 30, filters, orderBy: 'fullName', order: 'desc' },
    { skip: !filters } // Optionally skip initial load until filters are set
  );

  // Triggered whenever the user selects a user from the list
  const handleChange = (event, value) => {
    setSelectedUser(value);
    onUserSelected(value);
  };

  // Triggered when the user types in the autocomplete field
  const handleFilterChange = (event, newFilterValue) => {
    setFilters([{ column: 'fullName', value: newFilterValue }]);
  };

  return (
    <Box mt={2}>
      <Autocomplete
        options={usersData?.data || []}
        getOptionLabel={(option) => option.fullName}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        value={selectedUser}
        onChange={handleChange}
        onInputChange={handleFilterChange}
        renderOption={(props, option) => (
          <li {...props} key={option._id}>
            {option.fullName}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label="From User" variant="outlined" fullWidth />}
      />
    </Box>
  );
}

export default UserAutocomplete;
