import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Chip, Box } from '@mui/material';

const AccumulatingSelect = ({ label, value, options, onChange }) => {
  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    onChange([...value, newValue]); // Add a new instance of the selected value
  };

  const handleDelete = (indexToDelete) => {
    const updatedValue = value.filter((_, index) => index !== indexToDelete);
    onChange(updatedValue); // Remove the item at the specified index
  };

  return (
    <FormControl fullWidth variant="outlined" margin="dense">
      <InputLabel>{label}</InputLabel>
      <Select
        value="" // Keep empty to allow adding duplicates
        onChange={handleSelectChange}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.title}>
            {option.title}
          </MenuItem>
        ))}
      </Select>

      <Box mt={2} display="flex" flexWrap="wrap">
        {value.map((item, index) => (
          <Chip
            key={`${item}-${index}`} // Unique key per duplicate item
            label={item}
            onDelete={() => handleDelete(index)}
            style={{ margin: '4px' }}
          />
        ))}
      </Box>
    </FormControl>
  );
};

export default AccumulatingSelect;
