export const filtersConfig = [
  { label: 'Full Name', value: 'fullName', type: 'text' },
  { label: 'Gender', value: 'gender', type: 'select', options: ['male', 'female'] },
  { label: 'Mobile Number', value: 'mobileNumber', type: 'text' },
  { label: 'Date of Birth', value: 'dateOfBirth', type: 'date' },
  { label: 'Discreet Mode', value: 'discreetMode', type: 'boolean' },
  { label: 'Profile Complete', value: 'isComplete', type: 'boolean' },
  { label: 'Is Fake', value: 'isFake', type: 'boolean' },
  {
    label: 'Looking For',
    value: 'lookingFor',
    type: 'multi-select',
    options: ['dating', 'short-term', 'chats', 'long-term'],
  },
  { label: 'Age Range', value: 'ageRange', type: 'range', min: 18, max: 80 },
  { label: 'Remaining Likes', value: 'remainingLikes', type: 'range', min: 0, max: 80 },
  { label: 'User Likes Count', value: 'userLikesCount', type: 'numeric' },
  {
    label: 'Sexual Orientation',
    value: 'sexualOrientation',
    type: 'select',
    options: ['straight', 'homosexual', 'bisexual', 'lesbian'],
  },
  { label: 'Want to See', value: 'wantToSee', type: 'select', options: ['men', 'women', 'both'] },
  {
    label: 'Interests',
    value: 'interests',
    type: 'multi-select',
    options: [
      'sport',
      'nature',
      'shopping',
      'music',
      'cinema',
      'theatre',
      'literature',
      'museums',
      'cooking',
      'photography',
      'food',
      'pets',
    ],
  },
];

export const fieldsConfig = [
  {
    label: 'Profile Images',
    value: 'profileImages',
    type: 'image-upload',
  },
  { label: 'Full Name', value: 'fullName', type: 'text' },
  { label: 'Mobile Number', value: 'mobileNumber', type: 'text' },
  { label: 'Date of Birth', value: 'dateOfBirth', type: 'date' },
  {
    label: 'Gender',
    value: 'gender',
    type: 'select',
    options: [
      { id: 'male', title: 'male' },
      { id: 'female', title: 'female' },
    ],
  },
  {
    label: 'Interests',
    value: 'interests',
    type: 'multi-select',
    max: 5,
    options: [
      { id: 'sport', title: 'sport' },
      { id: 'nature', title: 'nature' },
      { id: 'shopping', title: 'shopping' },
      { id: 'music', title: 'music' },
      { id: 'cinema', title: 'cinema' },
      { id: 'theatre', title: 'theatre' },
      { id: 'literature', title: 'literature' },
      { id: 'museums', title: 'museums' },
      { id: 'cooking', title: 'cooking' },
      { id: 'photography', title: 'photography' },
      { id: 'food', title: 'food' },
      { id: 'pets', title: 'pets' },
    ],
  },
  {
    label: 'Looking For',
    value: 'lookingFor',
    type: 'multi-select',
    options: [
      { id: 'dating', title: 'dating' },
      { id: 'short-term', title: 'short-term' },
      { id: 'chats', title: 'chats' },
      { id: 'long-term', title: 'long-term' },
    ],
  },
  { label: 'Distance', value: 'distance', type: 'numeric' },
  {
    label: 'Sexual Orientation',
    value: 'sexualOrientation',
    type: 'select',
    options: [
      { id: 'straight', title: 'straight' },
      { id: 'homosexual', title: 'homosexual' },
      { id: 'bisexual', title: 'bisexual' },
      { id: 'lesbian', title: 'lesbian' },
    ],
  },
  {
    label: 'Want to See',
    value: 'wantToSee',
    type: 'select',
    options: [
      { id: 'men', title: 'men' },
      { id: 'women', title: 'women' },
      { id: 'both', title: 'both' },
    ],
  },
  { label: 'Discreet Mode', value: 'discreetMode', type: 'boolean' },
  { label: 'Is Profile Complete', value: 'isComplete', type: 'boolean' },
  { label: 'Is Profile Fake', value: 'isFake', type: 'boolean' },
  {
    label: 'Subscription Type',
    value: 'subscriptionType',
    type: 'select',
    options: [
      { id: 'free', title: 'free' },
      { id: 'paid', title: 'paid' },
    ],
  },
  { label: 'About You', value: 'aboutYou', type: 'text' },
  { label: 'Age Range', value: 'ageRange', type: 'range', min: 18, max: 80 },
  {
    label: 'Location Coordinates',
    value: 'location.coordinates',
    type: 'coordinates',
  },
  { label: 'Block Incoming Messages', value: 'blockIncomingMessages', type: 'boolean' },
  { label: 'Remaining Likes', value: 'remainingLikes', type: 'numeric' },
  { label: 'User Likes Count', value: 'userLikesCount', type: 'numeric' },
  { label: 'Remaining Spins', value: 'remainingSpins', type: 'numeric' },
  {
    label: 'Subscription Gifts',
    value: 'subscriptionGifts',
    type: 'accumulating-select',
    options: [
      { id: 'heart', title: 'heart' },
      { id: 'rose', title: 'rose' },
      { id: 'kiss', title: 'kiss' },
      { id: 'love', title: 'love' },
    ],
  },
  {
    label: 'Paid Gifts',
    value: 'paidGifts',
    type: 'accumulating-select',
    options: [
      { id: 'heart', title: 'heart' },
      { id: 'rose', title: 'rose' },
      { id: 'kiss', title: 'kiss' },
      { id: 'love', title: 'love' },
    ],
  },
  {
    label: 'Status',
    value: 'status',
    type: 'select',
    options: [
      { id: 'active', title: 'active' },
      { id: 'banned', title: 'banned' },
    ],
  },
];
