import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Box,
  TextField,
  Button,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  FormControlLabel,
  Checkbox,
  Slider,
  Badge,
} from '@mui/material';
import Iconify from '../iconify';

function MultiFilter({ filtersConfig, filters, applyFilters }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempFilters, setTempFilters] = useState(filters);

  useEffect(() => {
    setTempFilters(filters);
  }, [filters]);

  const handleOpenFilterPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const handleAddFilter = () => {
    setTempFilters([...tempFilters, { column: '', value: '' }]);
  };

  const handleColumnChange = (index, column) => {
    const newFilters = tempFilters.map((filter) => ({ ...filter }));
    newFilters[index].column = column;
    setTempFilters(newFilters);
  };

  const handleValueChange = (index, value) => {
    const newFilters = tempFilters.map((filter) => ({ ...filter }));
    newFilters[index].value = value;
    setTempFilters(newFilters);
  };

  const handleRemoveFilter = (index) => {
    const newFilters = tempFilters.filter((_, i) => i !== index);
    setTempFilters(newFilters);
  };

  const handleApplyFilters = () => {
    applyFilters(tempFilters);
    handleCloseFilterPopover();
  };

  const renderFilterInput = (filter, index) => {
    const filterConfig = filtersConfig.find((col) => col.value === filter.column);

    if (!filterConfig) return null;

    switch (filterConfig.type) {
      case 'select':
        return (
          <FormControl size="small" variant="outlined" style={{ flexGrow: 1 }}>
            <InputLabel>Value</InputLabel>
            <Select value={filter.value} onChange={(e) => handleValueChange(index, e.target.value)} label="Value">
              {filterConfig.options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case 'boolean':
        return (
          <FormControlLabel
            control={
              <Checkbox checked={filter.value || false} onChange={(e) => handleValueChange(index, e.target.checked)} />
            }
            label={filterConfig.label}
            style={{ flexGrow: 1 }}
          />
        );

      case 'range':
        return (
          <Slider
            value={filter.value || [filterConfig.min, filterConfig.max]}
            onChange={(e, newValue) => handleValueChange(index, newValue)}
            valueLabelDisplay="auto"
            min={filterConfig.min}
            max={filterConfig.max}
            style={{ flexGrow: 1 }}
          />
        );

      case 'numeric':
        return (
          <TextField
            type="number"
            value={filter.value || ''}
            onChange={(e) => handleValueChange(index, e.target.value)}
            placeholder="Filter value"
            size="small"
            variant="outlined"
            style={{ flexGrow: 1 }}
          />
        );

      case 'date':
        return (
          <TextField
            type="date"
            value={filter.value || ''}
            onChange={(e) => handleValueChange(index, e.target.value)}
            size="small"
            variant="outlined"
            style={{ flexGrow: 1 }}
          />
        );

      case 'multi-select':
        return (
          <FormControl size="small" variant="outlined" style={{ flexGrow: 1 }}>
            <InputLabel>Value</InputLabel>
            <Select
              multiple
              value={filter.value || []}
              onChange={(e) => handleValueChange(index, e.target.value)}
              renderValue={(selected) => selected.join(', ')}
              label="Value"
            >
              {filterConfig.options.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={filter.value?.includes(option)} />
                  <Typography variant="body2">{option}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      default:
        return (
          <TextField
            value={filter.value || ''}
            onChange={(e) => handleValueChange(index, e.target.value)}
            placeholder="Filter value"
            size="small"
            variant="outlined"
            style={{ flexGrow: 1 }}
          />
        );
    }
  };

  return (
    <Box my={2}>
      <Box ml={3}>
        <Badge badgeContent={tempFilters.filter((f) => f.value !== '').length} color="secondary">
          <Button onClick={handleOpenFilterPopover} color="primary" aria-describedby={id}>
            <Iconify icon="mdi:filter-variant" sx={{ color: '#4A276B' }} />
            <Typography variant="button" ml={1} sx={{ color: '#4A276B' }}>
              Filters
            </Typography>
          </Button>
        </Badge>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilterPopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box p={2}>
          {tempFilters.map((filter, index) => (
            <Box key={index} display="flex" alignItems="center" gap={1} mt={2}>
              <IconButton onClick={() => handleRemoveFilter(index)} color="error" size="small">
                <Iconify icon="eva:close-outline" />
              </IconButton>

              <FormControl variant="outlined" size="small" style={{ width: '220px' }}>
                <InputLabel>Column</InputLabel>
                <Select
                  value={filter.column}
                  onChange={(e) => handleColumnChange(index, e.target.value)}
                  label="Column"
                >
                  {filtersConfig.map((col) => (
                    <MenuItem key={col.value} value={col.value}>
                      {col.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {renderFilterInput(filter, index)}
            </Box>
          ))}

          <Box display="flex" gap={2} mt={2}>
            <Button onClick={handleAddFilter} variant="outlined" color="primary">
              Add Filter
            </Button>
            <Button onClick={handleApplyFilters} variant="contained" color="primary">
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

export default MultiFilter;
