import React, { useState, useEffect } from 'react';
import { TextField, Button, Stack, List, ListItem, Typography, Paper } from '@mui/material';
import Geocoder from 'react-native-geocoding';
import { usePlacesAutocompleteQuery } from '../../redux/userManagement/userManagementApi';

const LocationInput = ({ initialCoordinates, onCoordinatesChange }) => {
  const [coordinates, setCoordinates] = useState(initialCoordinates || { lat: '', lng: '' });
  const [address, setAddress] = useState(initialCoordinates.name);
  const [inputValue, setInputValue] = useState('');
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);

  useEffect(() => {
    setCoordinates(initialCoordinates);
    setAddress(address || initialCoordinates.name);
  }, [initialCoordinates, address]);

  const { data: suggestions, refetch } = usePlacesAutocompleteQuery(inputValue, {
    skip: !suggestionsVisible || inputValue.length <= 3,
  });

  const handleAddressChange = (e) => {
    const input = e.target.value;
    setAddress(input);
    setInputValue(input);
    if (input.length > 3) {
      setSuggestionsVisible(true);
      refetch();
    } else {
      setSuggestionsVisible(false);
    }
  };

  const handleSuggestionClick = (location, description) => {
    setCoordinates({ lat: location.lat, lng: location.lng });
    setAddress(description);
    onCoordinatesChange({ lat: location.lat, lng: location.lng, name: description });
    setSuggestionsVisible(false);
  };

  return (
    <Stack spacing={2} sx={{ position: 'relative' }}>
      <Typography variant="body2" color="textSecondary">
        Coordinates: {coordinates.lat?.toFixed(6)}, {coordinates.lng?.toFixed(6)}
      </Typography>

      <TextField label="Address" variant="outlined" fullWidth value={address} onChange={handleAddressChange} />
      {suggestionsVisible && suggestions?.length > 0 && (
        <Paper
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 10,
            maxHeight: 200,
            overflowY: 'auto',
            border: '1px solid #ddd',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            mt: 1,
          }}
        >
          <List>
            {suggestions.map((suggestion) => (
              <ListItem
                button
                key={suggestion.place_id}
                onClick={() => handleSuggestionClick(suggestion.location, suggestion.description)}
                sx={{
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                {suggestion.description}
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Stack>
  );
};

export default LocationInput;
