export const filtersConfig = [
  { label: 'User', value: 'user', type: 'text' },
  { label: 'User Is Fake', value: 'userIsFake', type: 'boolean' },
  { label: 'Related User', value: 'relatedUser', type: 'text' },
  { label: 'Related User Is Fake', value: 'relatedUserIsFake', type: 'boolean' },
  {
    label: 'Type',
    value: 'type',
    type: 'multi-select',
    options: ['match', 'like', 'message', 'gift'],
  },
];
