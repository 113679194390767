import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';

import {
  Card,
  CardContent,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  TextField,
  Grid,
  Paper,
  Box,
  Avatar,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
// import man from '../assets/man.avif';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  useGetUserByIdQuery,
  useSetUserByIdMutation,
  useDeleteUserByIdMutation,
  useSetStatusByidMutation,
} from '../redux/userManagement/userManagementApi';
import { prepareSetUserDetailsData } from './utils';

const StyledCard = styled(Card)({
  width: '100%', // Set the width to 100% to match the parent Container
  margin: '0 auto',
  padding: (theme) => theme.spacing(2),
});

const StyledButton = styled(Button)({
  fontSize: '15px',
  marginTop: (theme) => theme.spacing(2),
});

const UserDetail = ({ user }) => {
  const [setUserStatus] = useSetStatusByidMutation();
  const [setUserDetails] = useSetUserByIdMutation();
  const { _id } = useParams();
  const {
    data: specificUser,
    isFetching,
    refetch,
  } = useGetUserByIdQuery(
    { _id },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [deleteUser] = useDeleteUserByIdMutation();
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const navigate = useNavigate();
  const [editedUser, setEditedUser] = useState({
    fullName: '',
    gender: '',
    sexualOrientation: '',
    aboutYou: '',
    status: '',
    lookingFor: [],
    wantToSee: '',
    dateOfBirth: null,
    remainingLikes: 0,
    remainingSpins: 0,
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedInterests, setSelectedInterests] = React.useState(specificUser?.data?.interests || []);
  const [lookingFor, setLookingFor] = React.useState(specificUser?.data?.lookingFor || []);

  useEffect(() => {
    setEditedUser({
      fullName: specificUser?.data?.fullName,
      gender: specificUser?.data?.gender,
      sexualOrientation: specificUser?.data?.sexualOrientation,
      aboutYou: specificUser?.data?.aboutYou,
      status: specificUser?.data?.status,
      wantToSee: specificUser?.data?.wantToSee,
      interests: specificUser?.data?.interests,
      lookingFor: specificUser?.data?.lookingFor,
      dateOfBirth: specificUser?.data?.dateOfBirth,
      remainingLikes: specificUser?.data?.remainingLikes,
      remainingSpins: specificUser?.data?.remainingSpins,
    });
    setSelectedInterests(specificUser?.data?.interests);
    setLookingFor(specificUser?.data?.lookingFor);
  }, [specificUser]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevUser) => {
      // Check if the new value is different from the current value
      if (prevUser[name] !== value) {
        return {
          ...prevUser,
          [name]: value,
        };
      }
      return prevUser;
    });
  };

  const handleCheckboxChange = (event) => {
    const name = 'lookingFor';
    const { value } = event.target;
    setEditedUser((prevUser) => {
      // Check if the new value is different from the current value
      if (prevUser[name] !== value) {
        return {
          ...prevUser,
          [name]: value,
        };
      }
      return prevUser;
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      console.log('valida', editedUser.lookingFor);

      const isUserDataValid =
        editedUser.fullName &&
        editedUser.gender &&
        editedUser.sexualOrientation &&
        editedUser.aboutYou &&
        editedUser.status &&
        editedUser.lookingFor &&
        editedUser.wantToSee &&
        editedUser.interests &&
        editedUser.dateOfBirth;

      if (!isUserDataValid) {
        toast.error('Please enter all the required credentials', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        const preparedData = prepareSetUserDetailsData(editedUser, selectedImage);
        const response = await setUserDetails({ preparedData, _id });
        console.log('res', response);

        if (response?.data?.status === 200) {
          refetch();
          toast.success('User details updated successfully');
          setEditedUser({
            fullName: '',
            gender: '',
            sexualOrientation: '',
            aboutYou: '',
            wantToSee: '',
            interests: '',
            dateOfBirth: '',
          });
          setEdit(false);
        } else {
          toast.error('Failed to update user details');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while updating user details');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (userId) => {
    const confirmation = window.confirm('Are you sure you want to delete this user?');
    if (confirmation) {
      deleteUser(userId);
      navigate('/dashboard/user');
      toast.success('User has been Deleted');
    }
  };

  const handleSuspend = async () => {
    try {
      await setUserStatus({
        status: 'banned',
        userId: _id,
      });
      toast.success('User has been Successfully Banned');
      await refetch(); // Wait for the refetch to complete
    } catch (error) {
      console.error('Error while suspending the user:', error);
    }
  };

  const handleUnSuspend = async () => {
    console.log('ID =====', _id);

    try {
      const response = await setUserStatus({
        status: 'active',
        userId: _id,
      });

      console.log('RESPONSE ======== ', response);
      toast.success('User has been Successfully UnBanned');
      await refetch();
    } catch (error) {
      console.error('Error while unsuspending the user:', error);
    }
  };

  const handleBack = () => {
    navigate('/dashboard/user');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const imageURL = URL.createObjectURL(file);
      setSelectedImageURL(imageURL);
      setSelectedImage(file);
    }
  };

  const myImg = `${specificUser?.data?.profileImages?.find((item) => item?.orderId === 1).uri}`;

  const transformGenderForDisplay = (value) => {
    if (value === 'male') {
      return 'Male';
    }
    if (value === 'female') {
      return 'Female';
    }
    return value;
  };
  if (isFetching) {
    return <div>Loading...</div>;
  }
  const handleDateChange = (date) => {
    setEditedUser({
      dateOfBirth: JSON.stringify(date),
      fullName: editedUser.fullName,
      gender: editedUser.gender,
      sexualOrientation: editedUser.sexualOrientation,
      aboutYou: editedUser.aboutYou,
      status: editedUser.status,
      wantToSee: editedUser.wantToSee,
      interests: editedUser.interests,
      remainingLikes: editedUser.remainingLikes,
      remainingSpins: editedUser.remainingSpins,
    });
  };
  const data = [
    'sport',
    'nature',
    'shopping',
    'music',
    'cinema',
    'theatre',
    'literature',
    'museums',
    'photography',
    'cooking',
    'food',
    'pets',
  ];

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;
    console.log('target', name);
    if (name === 'lookingFor') {
      setLookingFor(typeof value === 'string' ? value.split(',') : value);
      setEditedUser((prevState) => ({
        ...prevState,
        lookingFor: typeof value === 'string' ? value.split(',') : value,
      }));
    } else {
      setSelectedInterests(typeof value === 'string' ? value.split(',') : value);

      setEditedUser((prevState) => ({
        ...prevState,
        interests: typeof value === 'string' ? value.split(',') : value,
      }));
    }
  };

  return loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        height: '100vh', // Adjust the height as needed
      }}
    >
      <TailSpin color="blue" radius={'8px'} sx={{ width: '100%' }} />
    </div>
  ) : (
    <Container component="main" maxWidth="lg" sx={{ width: '100%' }}>
      <StyledCard sx={{ width: '95%' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            User Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 2, width: '200px', height: '200px' }}>
                <img alt="" src={myImg} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2, display: 'flex', gap: '2px' }}>
                <Typography variant="subtitle1">Username: </Typography>
                <Typography variant="body1">{specificUser?.data?.fullName}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2, display: 'flex', gap: '2px' }}>
                <Typography variant="subtitle1">Gender: </Typography>
                <Typography variant="body1">{specificUser?.data?.gender}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2, display: 'flex', gap: '2px' }}>
                <Typography variant="subtitle1">Bio: </Typography>
                <Typography variant="body1">{specificUser?.data?.aboutYou}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2, display: 'flex', gap: '2px' }}>
                <Typography variant="subtitle1">Mobile Number:</Typography>
                <Typography variant="body1">{specificUser?.data?.mobileNumber}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2, display: 'flex', gap: '2px' }}>
                <Typography variant="subtitle1">Remaining Likes: </Typography>
                <Typography variant="body1">{specificUser?.data?.remainingLikes}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2, display: 'flex', gap: '2px' }}>
                <Typography variant="subtitle1">Remaining Spins: </Typography>
                <Typography variant="body1">{specificUser?.data?.remainingSpins}</Typography>
              </Paper>
            </Grid>
          </Grid>

          <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
            User Preference
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2, display: 'flex', gap: '2px' }}>
                <Typography variant="subtitle1">Sexual Orientation: </Typography>
                <Typography variant="body1">{specificUser?.data?.sexualOrientation}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2, display: 'flex', gap: '2px' }}>
                <Typography variant="subtitle1">Wants to see: </Typography>
                <Typography variant="body1">{specificUser?.data?.wantToSee}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2, display: 'flex', gap: '2px' }}>
                <Typography variant="subtitle1">Looking For: </Typography>
                {console.log(specificUser?.data, 'specificUser?.data -- ==')}
                <Typography variant="body1">{specificUser?.data?.lookingFor.join(' , ')}</Typography>
              </Paper>
            </Grid>
          </Grid>

          <StyledButton
            variant="contained"
            color="primary"
            sx={{ margin: '20px 10px 0px 0px', background: '#4A276B' }}
            onClick={handleBack}
          >
            Back
          </StyledButton>

          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => {
              setEdit(true);
            }}
            sx={{ margin: '20px 10px 0px 0px', background: '#4A276B' }}
          >
            Edit
          </StyledButton>

          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => {
              handleDelete(_id);
            }}
            sx={{ margin: '20px 10px 0px 0px', background: '#4A276B' }}
          >
            Delete User
          </StyledButton>

          {specificUser?.data?.status === 'active' && (
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => {
                handleSuspend();
              }}
              sx={{ margin: '20px 10px 0px 0px', background: '#4A276B' }}
            >
              Ban User
            </StyledButton>
          )}

          {specificUser?.data?.status === 'banned' && (
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => {
                handleUnSuspend();
              }}
              sx={{ margin: '20px 10px 0px 0px', background: '#4A276B' }}
            >
              UnBan User
            </StyledButton>
          )}

          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(`/userpayment/${_id}`);
            }}
            sx={{ margin: '20px 10px 0px 0px', background: '#4A276B' }}
          >
            Payment Details
          </StyledButton>
        </CardContent>
      </StyledCard>
      {edit && (
        <Container component="main" maxWidth="lg" sx={{ width: '100%', marginTop: '50px' }}>
          <StyledCard sx={{ width: '100%' }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Edit Details
              </Typography>
              <Button variant="outlined" component="label" htmlFor="image-input">
                Upload Image
                <input
                  id="image-input"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
              </Button>

              {selectedImageURL && (
                <div>
                  <img
                    alt="Selected"
                    src={selectedImageURL}
                    style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover' }}
                  />
                </div>
              )}
              <TextField
                label="Username"
                name="fullName"
                value={editedUser.fullName}
                disabled={!edit}
                fullWidth
                margin="normal"
                onChange={handleInputChange}
              />

              <TextField
                label="Phone Number"
                name="mobileNumber"
                value={specificUser?.data.mobileNumber}
                disabled={!edit}
                fullWidth
                margin="normal"
                onChange={handleInputChange}
              />

              <InputLabel>Gender</InputLabel>
              <Select
                label="Gender"
                name="gender"
                value={transformGenderForDisplay(editedUser.gender)}
                // value={specificUser?.data?.gender}

                onChange={handleInputChange}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>

              <TextField
                label="Bio"
                name="aboutYou"
                value={editedUser.aboutYou}
                disabled={!edit}
                fullWidth
                margin="normal"
                onChange={handleInputChange}
              />
              <TextField
                label="Remaining Likes"
                name="remainingLikes"
                value={editedUser.remainingLikes}
                disabled={!edit}
                fullWidth
                margin="normal"
                onChange={handleInputChange}
              />
              <TextField
                label="Remaining Spins"
                name="remainingSpins"
                value={editedUser.remainingSpins}
                disabled={!edit}
                fullWidth
                margin="normal"
                onChange={handleInputChange}
              />

              <InputLabel>Sexual Orientation</InputLabel>
              <Select
                label="Sexual Orientation"
                name="sexualOrientation"
                value={editedUser?.sexualOrientation}
                margin="normal"
                // value={specificUser?.data?.gender}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="straight">straight</MenuItem>
                <MenuItem value="homosexual">homosexual</MenuItem>
                <MenuItem value="bisexual">bisexual</MenuItem>
                <MenuItem value="lesbian">lesbian</MenuItem>
              </Select>

              <InputLabel>Interests</InputLabel>
              <Select
                label="Interests"
                name="interests"
                value={selectedInterests}
                margin="normal"
                onChange={handleChange}
                fullWidth
                multiple
              >
                {data.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>

              <InputLabel>Wants to see</InputLabel>
              <Select
                label="Wants to see"
                name="wantToSee"
                value={editedUser?.wantToSee}
                // value={specificUser?.data?.gender}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
              >
                <MenuItem value="men">men</MenuItem>
                <MenuItem value="women">women</MenuItem>
                <MenuItem value="both">both</MenuItem>
              </Select>

              <InputLabel>Looking For</InputLabel>
              <Select
                label="Looking For"
                name="lookingFor"
                value={lookingFor}
                onChange={handleChange}
                margin="normal"
                fullWidth
                multiple
              >
                <MenuItem value="dating">dating</MenuItem>
                <MenuItem value="short-term">short-term</MenuItem>
                <MenuItem value="chats">chats</MenuItem>
                <MenuItem value="long-term">long-term</MenuItem>
              </Select>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    disableFuture
                    label="Date of Birth"
                    value={dayjs(specificUser.data.dateOfBirth)}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </DemoContainer>
              </LocalizationProvider>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSave();
                }}
                sx={{ margin: '10px 10px 0px 0px', background: '#4A276B' }}
              >
                Save Changes
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => {
                  setEdit(false);
                }}
                sx={{ margin: '10px 10px 0px 0px', background: '#4A276B' }}
              >
                Cancel
              </StyledButton>
            </CardContent>
          </StyledCard>
        </Container>
      )}
    </Container>
  );
};

export default UserDetail;
