import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { toast } from 'react-toastify';

import { useSendMessageMutation } from '../../redux/userManagement/userManagementApi';
import SingleImageUpload from '../../sections/@dashboard/user/SingleImageUpload';
import UserAutocomplete from './UserAutocomplete';

function MessageModal({ open, onClose, socket, fromUserId, toUserIds }) {
  const [sendMessage] = useSendMessageMutation();

  const [selectedUser, setSelectedUser] = useState(fromUserId);
  const [messageText, setMessageText] = useState('');
  const [messageType, setMessageType] = useState('text');
  const [uploadedImage, setUploadedImage] = useState([]);

  useEffect(() => {
    if (fromUserId) {
      setSelectedUser(fromUserId);
    }
  }, [fromUserId]);

  const handleMessageSubmit = async () => {
    try {
      const response = await sendMessage({
        fromUserId: selectedUser,
        toUserIds: toUserIds.map((user) => user.userId), // Pass only user IDs to the API
        message: messageText,
      }).unwrap();

      // Helper function to get the fullName from the userId
      const getFullName = (userId) => toUserIds.find((user) => user.userId === userId)?.fullName || 'Unknown User';

      // Track if any messages were sent
      let anyMessageSent = false;

      // Loop through each recipient in the response
      response.data.forEach(({ room, receiverUser }) => {
        const fullName = getFullName(receiverUser);

        if (room) {
          if (messageType === 'text') {
            console.log({
              room: room.id, // room ID from the response
              sender: selectedUser,
              receiver: receiverUser,
              text: messageText,
            });
            // Emit a message for each valid room
            socket.emit('send-message', {
              room: room.id, // room ID from the response
              sender: selectedUser,
              receiver: receiverUser,
              text: messageText,
            });
          } else {
            socket.emit('send-image', {
              room: room.id, // room ID from the response
              sender: selectedUser,
              receiver: receiverUser,
              image: uploadedImage.uri,
            });
          }
          anyMessageSent = true;
          toast.info(`Message sent to ${fullName}`);
        } else {
          // Toast for any user where no room was created
          toast.warn(`No message sent to ${fullName} - no match found`);
        }
      });

      onClose();
      setMessageText('');
    } catch (error) {
      console.error('Failed to send message:', error);
      toast.error('Failed to send message');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Send Message</DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        {!fromUserId && (
          <Box mt={2}>
            <UserAutocomplete onUserSelected={setSelectedUser} />
          </Box>
        )}

        <Box mt={2}>
          <Select value={messageType} onChange={(e) => setMessageType(e.target.value)} fullWidth variant="outlined">
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="image">Image</MenuItem>
          </Select>
        </Box>

        {messageType === 'text' ? (
          <TextField
            label="Message"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        ) : (
          <SingleImageUpload onUploadedImage={setUploadedImage} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleMessageSubmit}
          color="primary"
          disabled={
            !selectedUser || (messageType === 'text' && !messageText) || (messageType === 'image' && !uploadedImage)
          }
        >
          Send {messageType === 'text' ? 'Message' : 'Image'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageModal;
