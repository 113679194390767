import React, { useState } from 'react';
import { Button, Stack, Typography, IconButton } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { styled } from '@mui/system';
import { useUploadImageMutation } from '../../../redux/userManagement/userManagementApi';
import Iconify from '../../../components/iconify';

const ImageInput = styled('input')({
  display: 'none',
});

const SingleImageUpload = ({ onUploadedImage }) => {
  const [uploadImage, { isLoading }] = useUploadImageMutation();
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleFileSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      try {
        const formData = new FormData();
        formData.append('image', selectedFile);

        const response = await uploadImage(formData).unwrap();
        setUploadedImage(response.profileImages[0]); // Update the component's state
        if (onUploadedImage) {
          onUploadedImage(response.profileImages[0]); // Pass the uploaded image back to the parent
        }
      } catch (error) {
        console.error('Image upload failed:', error);
      }
    }
  };

  return (
    <Stack spacing={2} alignItems="center" sx={{ marginTop: 5 }}>
      {uploadedImage ? (
        <ImageList cols={1} gap={8} sx={{ maxWidth: 500}}>
          <ImageListItem sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <img
              src={uploadedImage.uri}
              alt="Profile"
              loading="lazy"
              style={{ borderRadius: '8px', objectFit: 'cover', height: 100 }}
            />
          </ImageListItem>
        </ImageList>
      ) : (
        <Typography variant="body2" color="text.secondary">
          No image uploaded
        </Typography>
      )}

      <label htmlFor="upload-button">
        <ImageInput id="upload-button" type="file" accept="image/*" onChange={handleFileSelect} />
        <Button
          variant="outlined"
          component="span"
          startIcon={<Iconify icon="mdi:upload" />}
          sx={{ mt: 2 }}
          disabled={isLoading}
        >
          {isLoading ? 'Uploading...' : 'Select Image'}
        </Button>
      </label>
    </Stack>
  );
};

export default SingleImageUpload;
