import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  Slider,
  Typography,
  Checkbox,
} from '@mui/material';
import LocationInput from './LocationInput';
import ImageUpload from './ImageUpload';
import AccumulatingSelect from './AccumulatingSelect';

const DrawerForm = ({ open, onClose, fieldsConfig, initialData = {}, onSave }) => {
  const [formData, setFormData] = useState({ ...initialData });

  useEffect(() => {
    // Initialize form data with default values for boolean fields set to false
    const initializedData = fieldsConfig.reduce(
      (acc, field) => {
        if (field.type === 'boolean') {
          // Check if the field already has a value in initialData
          acc[field.value] = initialData[field.value] ?? false; // Use the existing value or default to false
        }
        return acc;
      },
      { ...initialData }
    );

    setFormData(initializedData);
  }, [initialData, fieldsConfig]);

  const handleChange = (fieldPath, value) => {
    setFormData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)); // Deep copy of formData
      const keys = fieldPath.split('.');
      let current = newData;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          if (!current[key]) current[key] = {}; // Create nested object if it doesn't exist
          current = current[key];
        }
      });

      return newData;
    });
  };

  const handleSubmit = () => {
    onSave(formData); // Pass form data to onSave callback
  };

  const getNestedValue = (obj, value) =>
    value.split('.').reduce((current, key) => (current ? current[key] : undefined), obj);

  const renderField = (fieldConfig) => {
    const { label, value, type, options, min, max } = fieldConfig;

    const textValue = getNestedValue(formData, value);

    const maxSelections = fieldConfig?.max || null;

    const handleMultiSelectChange = (fieldValue, selectedValues) => {
      if (!maxSelections || selectedValues.length <= maxSelections) {
        handleChange(fieldValue, selectedValues);
      } else {
        alert(`You can only select up to ${maxSelections} options.`);
      }
    };

    switch (type) {
      case 'text':
        return (
          <TextField
            label={label}
            variant="outlined"
            fullWidth
            margin="dense"
            value={textValue || ''}
            onChange={(e) => handleChange(value, e.target.value)}
          />
        );

      case 'numeric':
        return (
          <TextField
            label={label}
            variant="outlined"
            fullWidth
            margin="dense"
            type="number"
            value={textValue || ''}
            onChange={(e) => handleChange(value, parseFloat(e.target.value))}
          />
        );

      case 'select':
        return (
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel>{label}</InputLabel>
            <Select value={formData[value] || ''} onChange={(e) => handleChange(value, e.target.value)} label={label}>
              {options.map((option) => (
                <MenuItem key={option.id} value={option.title}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case 'multi-select':
        return (
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel>{label}</InputLabel>
            <Select
              multiple
              value={formData[value] || []}
              onChange={(e) => handleMultiSelectChange(value, e.target.value)}
              renderValue={(selected) => selected.join(', ')}
              label={label}
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.title}>
                  <Checkbox checked={formData[value]?.includes(option.title) || false} />
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case 'accumulating-select':
        return (
          <AccumulatingSelect
            label={label}
            value={formData[value] || []}
            options={options}
            onChange={(newSelections) => handleChange(value, newSelections)}
          />
        );

      case 'boolean':
        return (
          <FormControlLabel
            control={<Switch checked={!!formData[value]} onChange={(e) => handleChange(value, e.target.checked)} />}
            label={label}
          />
        );

      case 'range':
        return (
          <>
            <Typography gutterBottom>{label}</Typography>
            <Slider
              value={[formData[value]?.start || min, formData[value]?.end || max]}
              onChange={(e, newValue) => handleChange(value, { start: newValue[0], end: newValue[1] })}
              valueLabelDisplay="auto"
              min={min}
              max={max}
            />
          </>
        );

      case 'date':
        return (
          <TextField
            label={label}
            type="date"
            variant="outlined"
            fullWidth
            margin="dense"
            value={formData[value] ? new Date(formData[value]).toISOString().substring(0, 10) : ''}
            onChange={(e) => handleChange(value, e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        );

      case 'coordinates': {
        const initialLatLng = Array.isArray(formData.location?.coordinates)
          ? {
              lat: formData.location.coordinates[0],
              lng: formData.location.coordinates[1],
              name: formData.location.name,
            }
          : { lat: 40.7128, lng: -74.006 };

        return (
          <LocationInput
            initialCoordinates={initialLatLng}
            onCoordinatesChange={(newCoords) => {
              handleChange('location.coordinates', [newCoords.lat, newCoords.lng]);
              handleChange('location.name', newCoords.name);
            }}
          />
        );
      }

      case 'image-upload':
        return (
          <ImageUpload
            profileImages={formData[value] || []}
            onImagesUpdate={(newImages) => handleChange(value, newImages)}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={400} p={3} role="presentation">
        <Typography variant="h6">{initialData ? 'Edit' : 'Create'} Record</Typography>
        <Box mt={2}>
          {fieldsConfig.map((fieldConfig) => (
            <Box key={fieldConfig.value} mb={2}>
              {renderField(fieldConfig)}
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Button onClick={onClose} color="secondary" style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerForm;
